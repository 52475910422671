//theme
//@import "primereact/resources/themes/lara-dark-purple/theme.css";

//core
@import "primereact/resources/primereact.min.css";

@import "primeflex/primeflex.css";

//custom
@import "assets/scss/custom";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.p-datatable .p-datatable-header {
  padding: 0 0 1rem 0;
  border: none;
}

.p-multiselect-label {
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
}

.card-layer-title {
  margin: -20px;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #0fa817;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: white;
}